import React,{Component} from 'react';
import { NavLink } from 'react-router-dom';
import Trends from './partials/Trends-home4';
//import './home4.css';


class Blockproducers extends React.Component {
   componentWillMount () {
  
       const link  = document.createElement('link');
    
    link.rel  = 'stylesheet';
    link.type = 'text/css';
    link.href = 'https://uchatr.com/workpoint/assets/styles/home4.css';
    
    document.head.appendChild(link);

      
    }
   render(){
 
    return(
        <div class="gmxpage block newpagedesign home4 homenewdesignload workpointhomepage">
            <div class="flex-section dark-gradient wf-section hed">
                <div class="flex-section">
                    <div data-collapse="medium" data-animation="default" data-duration="800" data-easing="ease-in-cubic" data-easing2="ease-out-quart" role="banner" class="navbarnew transparentbg w-nav">
                        <div class="nav-container w-container">
                            <a href="/" class="nav-logo w-inline-block"><img src="https://uchatr.com/workpoint/assets/images/P-Icon.png" width="85" alt="" class="logo-5" /></a>
                            <nav role="navigation" class="navmenulinks w-nav-menu">
                                <div class="nav-menu homehidden">
                                    <div data-hover="true" data-delay="0" class="dropdown w-dropdown hjk">
                                        <div class="navlink-dropdown whitetext w-dropdown-toggle" id="w-dropdown-toggle-0" aria-controls="w-dropdown-list-0" aria-haspopup="menu" aria-expanded="false" role="button" tabindex="0">
                                            <div class="chevrondown w-icon-dropdown-toggle" aria-hidden="true"></div>
                                            <div class="text-block">By Role</div>
                                        </div>
                                        <nav class="dropdown-list w-dropdown-list" id="w-dropdown-list-0" aria-labelledby="w-dropdown-toggle-0">
                                            <div class="dropdownnavlinks">
                                                <a href="/designer" class="dropdownlink w-dropdown-link" tabindex="0">For Designers</a><a href="/teams" class="dropdownlink w-dropdown-link" tabindex="0">For Teams</a>
                                                <a href="/developer" class="dropdownlink w-dropdown-link" tabindex="0">For Developers</a>
                                            </div>
                                        </nav>
                                    </div>
                                    <div data-hover="true" data-delay="0" class="dropdown w-dropdown hjk">
                                        <div class="navlink-dropdown whitetext hide w-dropdown-toggle" id="w-dropdown-toggle-1" aria-controls="w-dropdown-list-1" aria-haspopup="menu" aria-expanded="false" role="button" tabindex="0">
                                            <div class="chevrondown w-icon-dropdown-toggle" aria-hidden="true"></div>
                                            <div class="text-block">By Channel</div>
                                        </div>
                                        <nav class="dropdown-list w-dropdown-list" id="w-dropdown-list-1" aria-labelledby="w-dropdown-toggle-1">
                                            <div class="dropdownnavlinks">
                                                <a href="/create-alexa-skill" class="dropdownlink w-dropdown-link" tabindex="0">Alexa Skills</a>
                                                <a href="/create-google-action" class="dropdownlink w-dropdown-link" tabindex="0">Google Actions</a><a href="/ivr-builder" class="dropdownlink w-dropdown-link" tabindex="0">IVR's</a>
                                                <a href="/chatbots" class="dropdownlink w-dropdown-link" tabindex="0">Chatbots</a><a href="/in-car-assistant" class="dropdownlink w-dropdown-link" tabindex="0">In-car Assistants</a>
                                                <a href="/in-app-assistant" class="dropdownlink w-dropdown-link" tabindex="0">In-app Assistants</a>
                                            </div>
                                        </nav>
                                    </div>
                                    <div data-hover="true" data-delay="0" class="dropdown w-dropdown hjk">
                                        <div class="navlink-dropdown whitetext w-dropdown-toggle" id="w-dropdown-toggle-2" aria-controls="w-dropdown-list-2" aria-haspopup="menu" aria-expanded="false" role="button" tabindex="0">
                                            <div class="chevrondown w-icon-dropdown-toggle" aria-hidden="true"></div>
                                            <div class="text-block">Resources</div>
                                        </div>
                                        <nav class="dropdown-list w-dropdown-list" id="w-dropdown-list-2" aria-labelledby="w-dropdown-toggle-2">
                                            <div class="dropdownnavlinks">
                                                <a href="#" target="_blank" class="dropdownlink w-dropdown-link" tabindex="0">Documentation</a>
                                                <a href="#" class="dropdownlink w-dropdown-link" tabindex="0">Tutorials</a>
                                                <a href="#" target="_blank" class="dropdownlink w-dropdown-link" tabindex="0">Community</a>
                                                <a href="/events" class="dropdownlink w-dropdown-link" tabindex="0">Events</a><a href="/blog" class="dropdownlink w-dropdown-link" tabindex="0">Blog</a>
                                            </div>
                                        </nav>
                                    </div>
                                  
                                    
                                </div>
                                <div class="navcta">
                                <NavLink to="/workpoint/prospecting" class="navlink-button sign-in whitesignin w-nav-link hjk">Prospecting</NavLink>
                                <NavLink to="/workpoint/automation" class="navlink-button sign-in whitesignin w-nav-link hjk">Automation</NavLink> 
                                
                                <NavLink to="/workpoint/login" class="navlink-button sign-in whitesignin w-nav-link hjk">Log In</NavLink>
                                
                                <a href="/workpoint/get-started/signup" class="btn-lg btn-flat btn-outline ml-3 desk getnewhome" >Get Started</a>
                                    
                                </div>
                            </nav>
                            <div
                                data-w-id="1f0a3c8a-cfce-9696-ae5e-34322a4a1337"
                                class="menu-button w-nav-button gq"
                                
                                aria-label="menu"
                                role="button"
                                tabindex="0"
                                aria-controls="w-nav-overlay-0"
                                aria-haspopup="menu"
                                aria-expanded="false"
                            >
                                <img src="https://uchatr.com/workpoint/assets/images/home4/5fd6310ed7b9bf44c616bd44_Menu%20Icon.svg" loading="lazy" width="24" alt="" class="menu-icon" />
                                <div class="menuline-top"></div>
                                <div class="menuline-top-center"></div>
                                <div class="menuline-top-bottom"></div>
                            </div>
                        </div>
                        <div class="w-nav-overlay" data-wf-ignore="" id="w-nav-overlay-0"></div>
                    </div>
                    <div class="container designhero">
                        <div
                            data-w-id="5ef28095-4fc7-2e3f-62d0-0a2056a773a1 zs"
                           
                            class="gradientext-wrapper herotopmargin"
                        >
                            <h2 class="designertab-h2 hometopzero">Prospecting&nbsp;Automation</h2>
                        </div>
                        <h1
                            data-w-id="5ef28095-4fc7-2e3f-62d0-0a2056a773a4 zs"
                           
                            class="bigheading whitebig-h1 herovers onew"
                        >
                            Unlock a New Pipeline Channel
                        </h1>
                        <p
                            data-w-id="5ef28095-4fc7-2e3f-62d0-0a2056a773a8 zs" 
                            
                            class="designer-p1 designhero whitep1 "
                        >
                            Anonymously see prospects every move. WorkPoint tracking <span className='mobileview'>identifies intent and turns former leads.</span>
                           
                            <span class="dfh"> Anonymously see prospects every move. WorkPoint tracking  &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;identifies intent and turns former leads.</span>
                        </p>
                         <p class="deski">identifies intent and turns former leads.</p>
                        <div
                            data-w-id="5ef28095-4fc7-2e3f-62d0-0a2056a773aa zs"
                            
                            class="hero-cta getstarted"
                        >
                            <a href="https://uchatr.com/workpoint/get-started/signup" class="fill singlemarginzero w-inline-block homebh ">Get Started
                            <svg focusable="false" class="ibm-duo-u--link-icon--arrow-right" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" fill="currentColor" aria-hidden="true" width="20" height="20" viewBox="0 0 20 20" slot="icon"><path d="M11.8 2.8L10.8 3.8 16.2 9.3 1 9.3 1 10.7 16.2 10.7 10.8 16.2 11.8 17.2 19 10z"></path></svg>
                            </a>
                        </div>
                    </div>
                  
                </div>
            </div>
	
             <div class="logodiv flex-section channels">
                    <h4 class="build">Data Hub</h4>
                    <p>Database of over 200 million profiles is the most accurate in the industry.</p>
                  
                    <div class="img-box2 newd">
                    <img src="https://uchatr.com/workpoint/assets/images/linkedin_wp.png" alt=""  />
                </div>
                    <div class="img-box2 newd">
                    <img src="https://uchatr.com/workpoint/assets/images/zoom_wp.png" alt=""  />
                </div>
               
                <div class="img-box2 newd">
                    <img src="https://uchatr.com/workpoint/assets/images/msft_wp.png" alt=""  />
                </div>
                

                
                <img src="https://uchatr.com/workpoint/assets/images/home4/602f1896fb75bb414460c109_Shape-3.svg" loading="lazy" width="350" alt="" class="absolutebg-40" />
                </div>
                
                
                <div class="connecting-trends first-trends f1">
         <div class="container">
            <div class="row align-items-center rotate-in-mobile">
            <div class="col-md-6">
            <img src="https://uchatr.com/workpoint/assets/images/blue_arch.png" loading="lazy" width="350" alt="" class="absolutebg-40 ko" />

            <div class="card_area blocksectionbottom ">
            
           
                           
                        

                         
                           <div class="card_area_box d-none">
                               <div class="row box-heading-row align-items-start perc_box m-sm">
                                   <span class="rate-text"><div>8</div></span><span class="percent">%</span>
                               </div>
                               <div class="row m-sm nj">
                                 <div class="col">
                                   <h5 class="m-0 no-bg mrate yield-box-padding">3 months fixed rate</h5>
                                 </div>
                               </div>

                               <div class="row m-sm no-gutters yield-buttons-container">
                                 
                                 <button id="yield-btn-1-1" class="button mt-0 mb-0 yield-rate-btn mr-6 active">3 Mo</button>
                                 <button id="yield-btn-1-2" class="button mt-0 mb-0 yield-rate-btn mr-6">6 Mo</button>
                                 
                               </div>

                               <div class="row">
                                 <div class="col">
                                   <h3 class="yield-box-padding card_area_subheading">Fixed short term</h3>
                                 </div>
                               </div>

                               <div class="row m-md">
                                 <div class="col">
                                   <span><div class="yield-box-padding">Retain flexibility by allocating over a shorter duration.<a href="javascript:void(0);"><img src="https://uchatr.com/workpoint/assets/images/info_icon.png" class="clickinfoimg" /></a></div></span>
                                 </div>
                               </div>

                               <div class="row disclosure-row">
                                 <div class="col">
                                   <div class="yield-box-padding"><span class="extra-small syu"><div><span>Rates are purely indicative and are subject to change pending availability  and market conditions. Withdrawal requests for fixed term accounts can be subject to an early withdrawal penalty..</span></div></span></div>
                                 </div>
                               </div>

                           </div>

                          
                           <div class="card_area_box">
                               <div class="row box-heading-row align-items-start perc_box m-sm">
                                   <span class="rate-text"><div>15</div></span><span class="percent">%</span>
                               </div>
                               <div class="row m-sm nj">
                                 <div class="col">
                                   <h5 class="m-0 no-bg mrate yield-box-padding">6 months fixed rate</h5>
                                 </div>
                               </div>

                               <div class="row m-sm no-gutters yield-buttons-container">
                                
                                 <button id="yield-btn-2-1" class="button mt-0 mb-0 yield-rate-btn mr-6">3 Mo</button>
                                 <button id="yield-btn-2-2" class="button mt-0 mb-0 yield-rate-btn mr-6 active">6 Mo</button>
                                 
                               </div>

                               <div class="row">
                                 <div class="col">
                                   <h3 class="yield-box-padding card_area_subheading">Fixed long term</h3>
                                 </div>
                               </div>

                               <div class="row m-md">
                                 <div class="col">
                                   <span><div class="yield-box-padding">Earn higher rates by locking in funds for longer.<a href="javascript:void(0);"><img src="https://uchatr.com/workpoint/assets/images/info_icon.png" class="clickinfoimg" /></a></div></span>
                                 </div>
                               </div>

                               <div class="row disclosure-row">
                                 <div class="col">
                                   <div class="yield-box-padding"><span class="extra-small syu"><div><span>Rates are purely indicative and are subject to change pending availability  and market conditions. Withdrawal requests for fixed term accounts can be subject to an early withdrawal penalty.. </span></div></span></div>
                                 </div>
                               </div>

                           </div>

                          
                           

                       </div>
           
                     </div>
            
            
            <div class="col-md-6">
               <div class="section-content">
               <h2 class="heading-3"> <span class="eyebrow">Engage former customers</span> 
               </h2>
                <h2>15% of your customers <br /> change jobs every 6 months</h2>
                  
                <div class="upperd">
                     <p class="cta-link link"><a href="https://uchatr.com/workpoint/get-started/signup" class="btn-link">Start Earning&nbsp; </a></p>
                     </div>
               </div> </div>
            </div>

            </div>
      </div>
       

      <div class="connecting-trends sect" >
         <div class="container">
            <div class="row align-items-center">
               <div class="col-md-6">
                  <div class="section-content">
                  <h2 class="heading-3"> <span class="eyebrow">Workpoint Automation</span> 
               </h2>
                  <h2>Save hours researching <br /> and nurturing prospects <br/> with WorkPoint Automation</h2>
                    <div class="upperd">
                     <p class="cta-link link"><a href="https://uchatr.com/workpoint/get-started/signup" class="btn-link">Sales Automations&nbsp; </a></p>
                     </div>
                  </div>
               </div>
               <div class="col-md-6">
                  <div class="logo-list">
                     <div class="row c-home-notifications workpointne">
                        <div class="col-6 col-md-5">
                           <div class="logo-wrap"><img src="https://uchatr.com/workpoint/assets/images/slack_integration.png" alt="slackintegration" /></div>
                        </div>
                        <div class="col-6 col-md-5">
                           <div class="logo-wrap"><img src="https://uchatr.com/workpoint/assets/images/outreach_integration.png" alt="Outreachintegration" /></div>
                        </div>
                        
                        
                        <div class="col-6 col-md-5">
                           <div class="logo-wrap"><img src="https://uchatr.com/workpoint/assets/images/salesforce_integration.png" alt="Salesforce" /></div>
                        </div>
                        <div class="col-6 col-md-5">
                           <div class="logo-wrap"><img src="https://uchatr.com/workpoint/assets/images/hubspot_integration.png" alt="Hubspot" /></div>
                        </div>
                        <div class="col-6 col-md-5">
                           <div class="logo-wrap"><img src="https://uchatr.com/workpoint/assets/images/bigquery_integration.png" alt="Google Big Query" /></div>
                        </div>
                        <div class="col-6 col-md-5">
                           <div class="logo-wrap"><img src="https://uchatr.com/workpoint/assets/images/outlook_integration.png" alt="Outlook" /></div>
                        </div>
                       
                       
                     </div>
                  </div>
             </div>
            </div>
         </div>
         <div class="grid triangle-pattern">
    <div class="triangle one" ></div>
    <div class="triangle one2" ></div>
    <div class="triangle " ></div>
    <div class="triangle" ></div>
    <div class="triangle" ></div>
    <div class="triangle"></div>
    <div class="triangle" ></div>
    <div class="triangle animated blue-2" ></div>
    <div class="triangle" ></div>
    <div class="triangle" ></div>
    <div class="triangle" ></div>
    <div class="triangle animated blue-2"></div>
    <div class="triangle"></div>
    <div class="triangle animated blue-2" ></div>
    <div class="triangle" ></div>
    <div class="triangle" ></div>
    <div class="triangle" ></div>
    <div class="triangle" ></div>
    <div class="triangle" ></div>
    <div class="triangle" ></div>
    <div class="triangle animated blue-1"></div>
    <div class="triangle" ></div>
    <div class="triangle" ></div>
    <div class="triangle" ></div>
    <div class="triangle" ></div>
    <div class="triangle" ></div>
    <div class="triangle" ></div>
    <div class="triangle" ></div>
    <div class="triangle" ></div>
    <div class="triangle" ></div>
    <div class="triangle" ></div>
    <div class="triangle" ></div>
    <div class="triangle" ></div>
    <div class="triangle" ></div>
    <div class="triangle" ></div>
    <div class="triangle" ></div>
    <div class="triangle" ></div>
    <div class="triangle" ></div>
    <div class="triangle" ></div>
    <div class="triangle animated blue-1"></div>
    <div class="triangle animated blue-1"></div>
    <div class="triangle" ></div>
    <div class="triangle" ></div>
    <div class="triangle" ></div>
    <div class="triangle" ></div>
    <div class="triangle" ></div>
    <div class="triangle" ></div>
    <div class="triangle animated blue-1"></div>
    <div class="triangle" ></div>
    <div class="triangle" ></div>
    <div class="triangle" ></div>
    <div class="triangle" ></div>
    <div class="triangle" ></div>
    <div class="triangle" ></div>
    <div class="triangle" ></div>
    <div class="triangle" ></div>
    <div class="triangle" ></div>
    <div class="triangle" ></div>
    <div class="triangle" ></div>
  
    <div class="triangle animated blue-1"></div>
    </div>
               
      </div>

      <div class="connecting-trends first-trends last">
         <div class="container">
            <div class="row align-items-center rotate-in-mobile">
            <div class="col-md-6">
            <Trends />
                     </div>
            
            
            <div class="col-md-6">
               <div class="section-content">
               <h2 class="heading-3"> <span class="eyebrow">INSTANT TRANSPARENCY</span> 
               </h2>
                <h2>Actionable prospecting  <br/>insights with automated <br/>workflows</h2>
                  
                  
                <div class="upperd">
                     <p class="cta-link link"><a href="https://uchatr.com/workpoint/get-started/signup" class="btn-link">Start Earning&nbsp; </a></p>
                     </div>
               </div> </div>
            </div>

            </div>
      </div>
      



<div id="Get-Started" class="flex-section bubble-top-left">
    <div class="container designerscroll">
        <div class="productcontent _120pxpadding">
           
           	<div class="faq-bottom">
    <div class="faq-title"><h2 class="heading-3 bo"> <span class="eyebrow">Frequently asked questions</span></h2></div>
    <div class="faq-item">
        <div class="faq--item ">
            <div class="faq-question show">
                <h5 class="h5-faq" >Do i need to register with 6Degree to place order?</h5>
                 <img
                    src="https://uchatr.com/workpoint/assets/images/add_plus_icon.png"
                    loading="lazy"
                    alt=""
                    class="faq-icon plus-c"
                    
                />
                 <img
                    src="https://uchatr.com/workpoint/assets/images/minus_icon.png"
                    loading="lazy"
                    alt=""
                    class="faq-icon minus-c"
                    
                />
            </div>
            <div class="faq-answer" >
                <p>Register simply by setting up an email address and a password. Sign in to view what is already in your shopping cart.</p>
            </div>
        </div>
        <div class="faq--item ">
            <div class="faq-question">
                <h5 class="h5-faq" >How do i register?</h5>
                <img
                    src="https://uchatr.com/workpoint/assets/images/add_plus_icon.png"
                    loading="lazy"
                    alt=""
                    class="faq-icon plus-c"
                    
                />
                 <img
                    src="https://uchatr.com/workpoint/assets/images/minus_icon.png"
                    loading="lazy"
                    alt=""
                    class="faq-icon minus-c"
                    
                />
            </div>
            <div class="faq-answer faq-none" >
                <p>
                   Register simply by setting up an email address and a password. Sign in to view what is already in your shopping cart.
                </p>
            </div>
        </div>
       
    </div>
</div>
        </div>
    </div>
    <img src="https://uchatr.com/workpoint/assets/images/home4/602f1896fb75bb414460c109_Shape-3.svg" loading="lazy" width="350" alt="" class="absolutebg-40 uiop"></img>
</div>


<div class="flex-section dark-gradient">
    <div class="container designerscroll">
        <div class="productcontent _96pxpadding _64pxbottom">
            <div class="gradientext-wrapper"><h2 class="designertab-h2 hometopzero">LINKEDIN DATA HUB</h2></div>
            <h2 class="bigheading whitebig-h1">Linkedin Prospecting Intelligence</h2>
            <p class="designer-p1 designhero whitep1">Scout Profiles Anonymously</p>
            <div
                data-w-id="5ebf2626-b437-da90-cfbe-0c8a0a751c02 vbf"
               
                class="buildanimation-wrapper home"
            >
                <div class="buildanimationimage-big">
                    <img src="https://uchatr.com/workpoint/assets/images/home4/5ffd06cc53b7aad5b5efdcb2_5fe2541860b0fea1137a9f73_VF_Block.svg" loading="lazy" alt="Voiceflow Logo Connector for APIs" />
                </div>
                <div data-w-id="5ebf2626-b437-da90-cfbe-0c8a0a751c05" class="pulsebig njh"></div>
                <div data-w-id="5ebf2626-b437-da90-cfbe-0c8a0a751c06" class="pulsesmall njh"></div>
                <img
                    src="https://uchatr.com/workpoint/assets/images/home4/602f27e0e683eb019bf0655c_Lines.png"
                    loading="lazy"
                    width="560"
                    sizes="100vw"
                    srcset="
                        /assets/images/home4/602f27e0e683eb019bf0655c_Lines-p-500.png   500w,
                        /assets/images/home4/602f27e0e683eb019bf0655c_Lines-p-800.png   800w,
                        /assets/images/home4/602f27e0e683eb019bf0655c_Lines-p-1080.png 1080w,
                        /assets/images/home4/602f27e0e683eb019bf0655c_Lines.png        1120w
                    "
                    alt=""
                    class="lines"
                />
                <img
                    src="https://uchatr.com/workpoint/assets/images/home4/5ffd19cd4e4d8657ca942587_Path%20Copy.png"
                    loading="lazy"
                    sizes="100vw"
                    srcset="
                        /assets/images/home4/5ffd19cd4e4d8657ca942587_Path%20Copy-p-500.png   500w,
                        /assets/images/home4/5ffd19cd4e4d8657ca942587_Path%20Copy-p-800.png   800w,
                        /assets/images/home4/5ffd19cd4e4d8657ca942587_Path%20Copy-p-1080.png 1080w,
                        /assets/images/home4/5ffd19cd4e4d8657ca942587_Path%20Copy.png        1125w
                    "
                    alt=""
                    class="blurtop"
                />
            </div>
            <div class="iopfotter">
            <div class="container">
                <div class="row flex-row row-footer-nav justify-content-start justify-content-md-between">
                    <div class="col col-12 col-lg-4 col-xl-3 my-1 order-last order-lg-first">
                        <div class="organization py-2 py-md-1 py-lg-0" >
                            <meta itemprop="name" content="BP" />
                            <figure class="logo">
                               
                                <a aria-current="page" class="active" href="/">
                                <img src="https://uchatr.com/workpoint/assets/images/P-Icon.png" class="desklogo fotter" />
                                </a>
                               
                            </figure>
                            <div>
                                
                                <div id="choice-footer-msg" class="choice-footer-msg ccpa-msg-added newb">
                                Linkedin Intelligence
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    

                    <div class="col col-12 col-lg-8 my-1 order-first order-lg-last">
                        <div class="row">

                          

                            <div class="col col-6 col-sm-3 mb-3 firstul" > 
                                <div class="footer-nav-wrapper py-2 py-md-1 py-lg-0" id="footer_resources_navigation">
                                    <div class="nav-header">
                                        Resources
                                    </div>
                                    <div class="menu-footer-resources-navigation-container">
                                        <ul id="menu-footer-resources-navigation" class="d-flex flex-column" >
                                            <li id="menu-item-337" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-337">
                                            <a href="#">Blog</a>
                                            </li>
                                            
                                            
                                            <li id="menu-item-63305" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-63305">
                                            <a href="#">Data Insights</a>
                                            </li>
                                           
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="col col-6 col-sm-3 mb-3">
                                <div class="footer-nav-wrapper py-2 py-md-1 py-lg-0" id="footer_solutions_navigation">
                                    <div class="nav-header">
                                    Access
                                    </div>
                                    <div class="menu-footer-solutions-container">
                                        <ul id="menu-footer-solutions" class=" d-flex flex-column" >
                                            <li id="menu-item-49409" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-49409">
                                           
                                             
                                                 <a href="/workpoint/get-started/signup" >Get Started</a>
                                            </li>
                                            <li id="menu-item-49410" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-49410">
                                            
                                                <NavLink to="/workpoint/login">Sign In</NavLink>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="col col-6 col-sm-3 col-lg-3">
                                <div class="footer-nav-wrapper py-2 py-md-1 py-lg-0" id="footer_company_navigation">
                                    <div class="nav-header">
                                        Company
                                    </div>
                                    <div class="menu-footer-company-navigation-container">
                                        <ul id="menu-footer-company-navigation" class=" d-flex flex-column" >
                                            <li id="menu-item-47827" class="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-47825 current_page_item menu-item-47827">
                                            
                                            <NavLink to="/workpoint/about">About</NavLink>
                                            </li>
                                            
                                            <li id="menu-item-47828" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-47828">
                                            <a href="#">Privacy</a>
                                            </li>
                                            <li id="menu-item-47828" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-47828">
                                            <a href="#">Terms</a>
                                            </li>
                                            <li id="menu-item-338" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-338">
                                            <a href="#">Contact</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="col col-6 col-sm-3 col-lg-3">
                                <div class="footer-nav-wrapper py-2 py-md-1 py-lg-0" id="footer_company_navigation">
                                    
                                    <div class="menu-footer-company-navigation-container">
                                        <ul id="menu-footer-company-navigation" class=" d-flex flex-column" >
                                            <li id="menu-item-47827" class="soc menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-47825 current_page_item menu-item-47827">
                                          <a href="#"> <svg class="svg-inline--fa fa-twitter fa-w-16" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="twitter" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                                    <path
                                        fill="currentColor"
                                        d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
                                    ></path>
                                </svg> Twitter </a> 
                                            </li>
                                            <li id="menu-item-60548" class="soc menu-item menu-item-type-custom menu-item-object-custom menu-item-60548">
                                            <a href="#"> <img src="https://uchatr.com/workpoint/assets/images/reddit.svg"  class="Sitemap__StyledIcon-uyke27-8 drcgmZ" /> Reddit </a>
                                            </li>
                                            <li id="menu-item-47828" class="soc menu-item menu-item-type-post_type menu-item-object-page menu-item-47828">
                                            <a href="#">  <svg
                                    class="svg-inline--fa fa-facebook fa-w-16"
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fab"
                                    data-icon="facebook"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512"
                                    data-fa-i2svg=""
                                >
                                    <path
                                        fill="currentColor"
                                        d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"
                                    ></path>
                                </svg> FaceBook </a>
                                            </li>
                                           
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div> </div>
    </div>
</div>

   

<div class="row -center hero-row popupde">
    <div class="close2">
        <a class="close-atag" href="javascript:void(0);">
            <img src="https://uchatr.com/workpoint/assets/images/xlose.png" />
        </a>
    </div>
<img class="feature-callout__img" src="https://luna1.co/005100.png" alt="Image of team" width="112" />
            <div class="signupForm-homepage-center col-xsmall-12 col-small-8 col-small-offset-2 col-medium-10 col-medium-offset-1 col-large-8 col-large-offset-2 col-xlarge-6 col-xlarge-offset-3">
                <h4 class="heading -spacing-2 -h3">Sign up</h4>
                <p class="subheading -spacing-1 hero-legal-copy">
                    By signing up, I agree to the Asana <a href="/terms#privacy-policy" class="css-1kfjk3x-Link">Privacy Policy</a> and <a href="/terms#terms-of-service" class="css-1kfjk3x-Link">Terms of Service</a>.
                </p>
                <form action="get-started/verify" method="get" novalidate="" class="signupForm hidden-logged-in signupForm-homepage-center" __bizdiag="1239199729" __biza="WJ__">
                    <div class="signupForm-container">
                        <div class="signupForm-row">
                        <div class="form-group">
                                
                                <input type="email" name="e" required  class="form-control" placeholder="name@company.com" id="work-email" />
                            </div>

                            <div class="submit-btn">
                                <button id="go-to-nextStep"  onClick={this.toggleBox} class="btn btn-lg btn-flat btn-block btn-primary">Next</button>
                            </div>
                        </div>
                    </div>
                </form>
                
            </div>
        </div>
    </div>
    );
}
}

export default Blockproducers;
