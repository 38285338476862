import React,{Component} from 'react';
import { NavLink } from 'react-router-dom';
import Trends from './partials/Trends-home4';
//import './home4.css';


class Blockproducers extends React.Component {
   componentWillMount () {
  
       const link  = document.createElement('link');
    
    link.rel  = 'stylesheet';
    link.type = 'text/css';
    link.href = '/assets/styles/home4.css?v=202421';
    
    //document.head.appendChild(link);

      
    }
   render(){
 
    return(
        <div class="gmxpage block newpagedesign home4 homenewdesignload workpointhomepage">
            <div class="flex-section dark-gradient wf-section hed">
                <div class="flex-section">
                    <div data-collapse="medium" data-animation="default" data-duration="800" data-easing="ease-in-cubic" data-easing2="ease-out-quart" role="banner" class="navbarnew transparentbg w-nav">
                        <div class="nav-container w-container">
                            <a href="/" class="nav-logo w-inline-block"><img src="/assets/images/home4/logo_home.png" width="250" alt="" class="logo-5" /></a>
                            <nav role="navigation" class="navmenulinks w-nav-menu">
                                <div class="nav-menu homehidden">
                                    <div data-hover="true" data-delay="0" class="dropdown w-dropdown hjk">
                                        <div class="navlink-dropdown whitetext w-dropdown-toggle" id="w-dropdown-toggle-0" aria-controls="w-dropdown-list-0" aria-haspopup="menu" aria-expanded="false" role="button" tabindex="0">
                                            <div class="chevrondown w-icon-dropdown-toggle" aria-hidden="true"></div>
                                            <div class="text-block">By Role</div>
                                        </div>
                                        <nav class="dropdown-list w-dropdown-list" id="w-dropdown-list-0" aria-labelledby="w-dropdown-toggle-0">
                                            <div class="dropdownnavlinks">
                                                <a href="/designer" class="dropdownlink w-dropdown-link" tabindex="0">For Designers</a><a href="/teams" class="dropdownlink w-dropdown-link" tabindex="0">For Teams</a>
                                                <a href="/developer" class="dropdownlink w-dropdown-link" tabindex="0">For Developers</a>
                                            </div>
                                        </nav>
                                    </div>
                                    <div data-hover="true" data-delay="0" class="dropdown w-dropdown hjk">
                                        <div class="navlink-dropdown whitetext hide w-dropdown-toggle" id="w-dropdown-toggle-1" aria-controls="w-dropdown-list-1" aria-haspopup="menu" aria-expanded="false" role="button" tabindex="0">
                                            <div class="chevrondown w-icon-dropdown-toggle" aria-hidden="true"></div>
                                            <div class="text-block">By Channel</div>
                                        </div>
                                        <nav class="dropdown-list w-dropdown-list" id="w-dropdown-list-1" aria-labelledby="w-dropdown-toggle-1">
                                            <div class="dropdownnavlinks">
                                                <a href="/create-alexa-skill" class="dropdownlink w-dropdown-link" tabindex="0">Alexa Skills</a>
                                                <a href="/create-google-action" class="dropdownlink w-dropdown-link" tabindex="0">Google Actions</a><a href="/ivr-builder" class="dropdownlink w-dropdown-link" tabindex="0">IVR's</a>
                                                <a href="/chatbots" class="dropdownlink w-dropdown-link" tabindex="0">Chatbots</a><a href="/in-car-assistant" class="dropdownlink w-dropdown-link" tabindex="0">In-car Assistants</a>
                                                <a href="/in-app-assistant" class="dropdownlink w-dropdown-link" tabindex="0">In-app Assistants</a>
                                            </div>
                                        </nav>
                                    </div>
                                    <div data-hover="true" data-delay="0" class="dropdown w-dropdown hjk">
                                        <div class="navlink-dropdown whitetext w-dropdown-toggle" id="w-dropdown-toggle-2" aria-controls="w-dropdown-list-2" aria-haspopup="menu" aria-expanded="false" role="button" tabindex="0">
                                            <div class="chevrondown w-icon-dropdown-toggle" aria-hidden="true"></div>
                                            <div class="text-block">Resources</div>
                                        </div>
                                        <nav class="dropdown-list w-dropdown-list" id="w-dropdown-list-2" aria-labelledby="w-dropdown-toggle-2">
                                            <div class="dropdownnavlinks">
                                                <a href="#" target="_blank" class="dropdownlink w-dropdown-link" tabindex="0">Documentation</a>
                                                <a href="#" class="dropdownlink w-dropdown-link" tabindex="0">Tutorials</a>
                                                <a href="#" target="_blank" class="dropdownlink w-dropdown-link" tabindex="0">Community</a>
                                                <a href="/events" class="dropdownlink w-dropdown-link" tabindex="0">Events</a><a href="/blog" class="dropdownlink w-dropdown-link" tabindex="0">Blog</a>
                                            </div>
                                        </nav>
                                    </div>
                                    
                                    
                                    
                                </div>
                                <div class="navcta">
                                <NavLink to="/services" class="navlink-button sign-in whitesignin w-nav-link hjk">Services</NavLink>
                                <NavLink to="/testimonials" class="navlink-button sign-in whitesignin w-nav-link hjk">Testimonials</NavLink> 
                                
                                <NavLink to="/login" class="navlink-button sign-in whitesignin w-nav-link hjk">Log In</NavLink>
                                
                                <a href="/signup" class="btn-lg btn-flat btn-outline ml-3 desk getnewhome" >Get Started</a>
                                    
                                </div>
                            </nav>
                            <div
                                data-w-id="1f0a3c8a-cfce-9696-ae5e-34322a4a1337"
                                class="menu-button w-nav-button gq"
                                
                                aria-label="menu"
                                role="button"
                                tabindex="0"
                                aria-controls="w-nav-overlay-0"
                                aria-haspopup="menu"
                                aria-expanded="false"
                            >
                                <img src="/assets/images/home4/5fd6310ed7b9bf44c616bd44_Menu%20Icon.svg" loading="lazy" width="24" alt="" class="menu-icon" />
                                <div class="menuline-top"></div>
                                <div class="menuline-top-center"></div>
                                <div class="menuline-top-bottom"></div>
                            </div>
                        </div>
                        <div class="w-nav-overlay" data-wf-ignore="" id="w-nav-overlay-0"></div>
                    </div>
                    <div class="container designhero">
                        <div
                            data-w-id="5ef28095-4fc7-2e3f-62d0-0a2056a773a1 zs"
                           
                            class="gradientext-wrapper herotopmargin"
                        >
                            <h2 class="designertab-h2 hometopzero">Lorem&nbsp;Ipsum</h2>
                        </div>
                        <h1
                            data-w-id="5ef28095-4fc7-2e3f-62d0-0a2056a773a4 zs"
                           
                            class="bigheading whitebig-h1 herovers onew"
                        >
                            What is Lorem Ipsum?
                        </h1>
                        <p
                            data-w-id="5ef28095-4fc7-2e3f-62d0-0a2056a773a8 zs" 
                            
                            class="designer-p1 designhero whitep1 "
                        >
                            Lorem Ipsum is simply dummy text of the printing and typesetting <span className='mobileview'>dummy text ever since the 1500s.</span>
                           
                            <span class="dfh"> Lorem Ipsum is simply dummy text of the printing and typesetting  &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;industry. Lorem Ipsum has been the industry's </span>
                        </p>
                         <p class="deski"> Lorem Ipsum has been the industry's standard dummy text.</p>
                        <div
                            data-w-id="5ef28095-4fc7-2e3f-62d0-0a2056a773aa zs"
                            
                            class="hero-cta getstarted"
                        >
                            <a href="/signup" class="fill singlemarginzero w-inline-block homebh ">Free Consultation
                            <svg focusable="false" class="ibm-duo-u--link-icon--arrow-right" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" fill="currentColor" aria-hidden="true" width="20" height="20" viewBox="0 0 20 20" slot="icon"><path d="M11.8 2.8L10.8 3.8 16.2 9.3 1 9.3 1 10.7 16.2 10.7 10.8 16.2 11.8 17.2 19 10z"></path></svg>
                            </a>
                        </div>
                    </div>
                  
                </div>
            </div>
	
             <div class="logodiv flex-section channels">
                    <h4 class="build">Lorem Ipsum</h4>
                    <p>Lorem Ipsum has been the industry's standard dummy text.</p>
                  
                    <div class="img-box2 newd">
                   <img src="/assets/images/msft_wp.png" alt=""  />
                </div>
                    <div class="img-box2 newd">
                   <img src="/assets/images/msft_wp.png" alt=""  />
                </div>
               
                <div class="img-box2 newd">
                    <img src="/assets/images/msft_wp.png" alt=""  />
                </div>
                

                
                <img src="/assets/images/home4/602f1896fb75bb414460c109_Shape-3.svg" loading="lazy" width="350" alt="" class="absolutebg-40" />
                </div>
                
                
                <div class="connecting-trends first-trends f1">
         <div class="container">
            <div class="row align-items-center rotate-in-mobile">
            <div class="col-md-6">
            <img src="/assets/images/blue_arch.png" loading="lazy" width="350" alt="" class="absolutebg-40 ko" />

            <div class="card_area blocksectionbottom ">
            
           
                           
                        

                         
                           <div class="card_area_box d-none">
                               <div class="row box-heading-row align-items-start perc_box m-sm">
                                   <span class="rate-text new30"><div>40</div></span><span class="percent">%</span>
                               </div>
                               <div class="row m-sm nj">
                                 <div class="col">
                                   <h5 class="m-0 no-bg mrate yield-box-padding">Lorem Ipsum</h5>
                                 </div>
                               </div>

                               <div class="row m-sm no-gutters yield-buttons-container">
                                 
                                 <button id="yield-btn-1-1" class="button mt-0 mb-0 yield-rate-btn mr-6 active">5+ Employees</button>
                                 <button id="yield-btn-1-2" class="button mt-0 mb-0 yield-rate-btn mr-6">25+ Employees</button>
                                 
                               </div>

                               <div class="row">
                                 <div class="col">
                                   <h3 class="yield-box-padding card_area_subheading">Lorem Ipsum</h3>
                                 </div>
                               </div>

                               <div class="row m-md">
                                 <div class="col">
                                   <span><div class="yield-box-padding">Lorem Ipsum has been the industry's.<a href="javascript:void(0);"><img src="/assets/images/info_icon.png" class="clickinfoimg" /></a></div></span>
                                 </div>
                               </div>

                               <div class="row disclosure-row">
                                 <div class="col">
                                   <div class="yield-box-padding"><span class="extra-small syu"><div><span>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</span></div></span></div>
                                 </div>
                               </div>

                           </div>

                          
                           <div class="card_area_box">
                               <div class="row box-heading-row align-items-start perc_box m-sm">
                                   <span class="rate-text new30"><div>30</div></span><span class="percent">%</span>
                               </div>
                               <div class="row m-sm nj">
                                 <div class="col">
                                   <h5 class="m-0 no-bg mrate yield-box-padding">Lorem Ipsum</h5>
                                 </div>
                               </div>

                               <div class="row m-sm no-gutters yield-buttons-container">
                                
                                 <button id="yield-btn-2-1" class="button mt-0 mb-0 yield-rate-btn mr-6">5+ Employees</button>
                                 <button id="yield-btn-2-2" class="button mt-0 mb-0 yield-rate-btn mr-6 active">25+ Employees</button>
                                 
                               </div>

                               <div class="row">
                                 <div class="col">
                                   <h3 class="yield-box-padding card_area_subheading">Lorem Ipsum</h3>
                                 </div>
                               </div>

                               <div class="row m-md">
                                 <div class="col">
                                   <span><div class="yield-box-padding">Lorem Ipsum is simply dummy text of the printing.<a href="javascript:void(0);"><img src="/assets/images/info_icon.png" class="clickinfoimg" /></a></div></span>
                                 </div>
                               </div>

                               <div class="row disclosure-row">
                                 <div class="col">
                                   <div class="yield-box-padding"><span class="extra-small syu"><div><span>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s. </span></div></span></div>
                                 </div>
                               </div>

                           </div>

                          
                           

                       </div>
           
                     </div>
            
            
            <div class="col-md-6">
               <div class="section-content">
               <h2 class="heading-3"> <span class="eyebrow">Why do we use it?</span> 
               </h2>
                <h2>Royalty manages your<br/> company specific Tax and <br/>employment filings in our<br/> touchpoint portal</h2>
                  
                <div class="upperd">
                     <p class="cta-link link"><a href="/signup" class="btn-link">Initiate Consultation&nbsp; </a></p>
                     </div>
               </div> </div>
            </div>

            </div>
      </div>
       

      <div class="connecting-trends sect" >
         <div class="container">
            <div class="row align-items-center">
               <div class="col-md-6">
                  <div class="section-content">
                  <h2 class="heading-3"> <span class="eyebrow">Lorem Ipsum</span> 
               </h2>
                  <h2>Industries Royalty Tax <br/>Specializes In</h2>
                    <div class="upperd">
                     <p class="cta-link link"><a href="/signup" class="btn-link">Initiate Consultation&nbsp; </a></p>
                     </div>
                  </div>
               </div>
               <div class="col-md-6">
                  <div class="logo-list">
                     <div class="row c-home-notifications workpointne">
                        <div class="col-6 col-md-5">
                           <div class="logo-wrap"></div>
                        </div>
                        <div class="col-6 col-md-5">
                           <div class="logo-wrap"></div>
                        </div>
                        
                        
                        <div class="col-6 col-md-5">
                           <div class="logo-wrap"></div>
                        </div>
                        <div class="col-6 col-md-5">
                           <div class="logo-wrap"></div>
                        </div>
                        <div class="col-6 col-md-5">
                           <div class="logo-wrap"></div>
                        </div>
                        <div class="col-6 col-md-5">
                           <div class="logo-wrap"></div>
                        </div>
                       
                       
                     </div>
                  </div>
             </div>
            </div>
         </div>
         <div class="grid triangle-pattern">
    <div class="triangle one" ></div>
    <div class="triangle one2" ></div>
    <div class="triangle " ></div>
    <div class="triangle" ></div>
    <div class="triangle" ></div>
    <div class="triangle"></div>
    <div class="triangle" ></div>
    <div class="triangle animated blue-2" ></div>
    <div class="triangle" ></div>
    <div class="triangle" ></div>
    <div class="triangle" ></div>
    <div class="triangle animated blue-2"></div>
    <div class="triangle"></div>
    <div class="triangle animated blue-2" ></div>
    <div class="triangle" ></div>
    <div class="triangle" ></div>
    <div class="triangle" ></div>
    <div class="triangle" ></div>
    <div class="triangle" ></div>
    <div class="triangle" ></div>
    <div class="triangle animated blue-1"></div>
    <div class="triangle" ></div>
    <div class="triangle" ></div>
    <div class="triangle" ></div>
    <div class="triangle" ></div>
    <div class="triangle" ></div>
    <div class="triangle" ></div>
    <div class="triangle" ></div>
    <div class="triangle" ></div>
    <div class="triangle" ></div>
    <div class="triangle" ></div>
    <div class="triangle" ></div>
    <div class="triangle" ></div>
    <div class="triangle" ></div>
    <div class="triangle" ></div>
    <div class="triangle" ></div>
    <div class="triangle" ></div>
    <div class="triangle" ></div>
    <div class="triangle" ></div>
    <div class="triangle animated blue-1"></div>
    <div class="triangle animated blue-1"></div>
    <div class="triangle" ></div>
    <div class="triangle" ></div>
    <div class="triangle" ></div>
    <div class="triangle" ></div>
    <div class="triangle" ></div>
    <div class="triangle" ></div>
    <div class="triangle animated blue-1"></div>
    <div class="triangle" ></div>
    <div class="triangle" ></div>
    <div class="triangle" ></div>
    <div class="triangle" ></div>
    <div class="triangle" ></div>
    <div class="triangle" ></div>
    <div class="triangle" ></div>
    <div class="triangle" ></div>
    <div class="triangle" ></div>
    <div class="triangle" ></div>
    <div class="triangle" ></div>
  
    <div class="triangle animated blue-1"></div>
    </div>
               
      </div>
    <div class="framer-JQ33n" id='ds'>
      <div class="framer-navnro container" data-framer-name="Callendly" name="Callendly">
                   
                    <div class="framer-k4rxum">
                        <div class="framer-a0qkzg" >
                            <div class="ssr-variant hidden-v14nzn">
                                <div class="framer-84phap"  data-framer-component-type="RichTextContainer">
                                    <h1
                                       
                                        class="framer-text h1fm"
                                    >
                                        Where does it come from?
                                    </h1>
                                </div>
                            </div>
                            <div class="ssr-variant hidden-v14nzn" >
                                <div class="framer-12du1bp"  data-framer-component-type="RichTextContainer">
                                    <h2
                                        
                                        class="framer-text ghme"
                                    >
                                        Contrary to popular belief, Lorem Ipsum is not simply random text. 
                                    </h2>
                                </div>
                            </div>
                        </div>
                        <div class="framer-afw8n8">
                            <div class="framer-do2lk3" >
                                <div class="framer-a1rphk sectiononeb show" data-framer-name="Card" id="section-1" name="Card" >
                                    <div class="framer-laafcp "  data-framer-component-type="RichTextContainer">
                                        <h3
                                            
                                            class="framer-text"
                                        >
                                            01
                                        </h3>
                                    </div>
                                    <div class="framer-k9z80w "  data-framer-component-type="RichTextContainer">
                                        <h3
                                            
                                            class="framer-text ghme1"
                                        >
                                            Lorem Ipsum is simply dummy text 
                                        </h3>
                                    </div>
                                   
                                    <a class="framer-1ymubkb framer-1bofbcw" data-framer-name="Button" name="Button" href="https://app.Royalty Tax.cloud/signup" target="_blank" rel="noopener">
                                        <div class="framer-1nukwtn"  data-framer-component-type="RichTextContainer">
                                            <p
                                                
                                                class="framer-text"
                                            >
                                                Sign up
                                            </p>
                                        </div>
                                        <div class="framer-ipj7rf">
                                            <div
                                                data-framer-component-type="SVG"
                                                data-framer-name="graphic"
                                                name="graphic"
                                                
                                                class="framer-1jy2b3h"
                                                aria-hidden="true"
                                            >
                                                <div class="svgContainer" >
                                                    <svg  viewBox="0 0 24 24" preserveAspectRatio="none" width="100%" height="100%"><use href="#svg413525620_236"></use></svg>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                    
                                </div>
                            </div>
                            <div class="framer-1ka9yz0 sectiononeb" data-framer-name="Card" id="section-2" name="Card" >
                                <div class="framer-794u9d">
                                    <div class="framer-15md0oe"  data-framer-component-type="RichTextContainer">
                                        <h3
                                           
                                            class="framer-text"
                                        >
                                            02
                                        </h3>
                                    </div>
                                    <div class="framer-ffi5rs">
                                       <div class="framer-n6dn7u"  data-framer-component-type="RichTextContainer">
                                            <h3
                                               
                                                class="framer-text ghme1"
                                            >
                                                  Lorem Ipsum
                                            </h3>
                                        </div>
                                        <div class="framer-rof8e4"  data-framer-component-type="RichTextContainer">
                                            <p
                                               
                                                class="framer-text ghme1"
                                            >
                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="framer-22qcqv sectiononeb" data-framer-name="Card" id="section-3" name="Card" >
                                <div class="framer-1gnp73m">
                                    <div class="framer-emu8e4"  data-framer-component-type="RichTextContainer">
                                        <h3
                                            
                                            class="framer-text"
                                        >
                                            03
                                        </h3>
                                    </div>
                                    <div class="framer-8uq5u9">
                                        <div class="framer-n6dn7u"  data-framer-component-type="RichTextContainer">
                                            <h3
                                               
                                                class="framer-text ghme1"
                                            >
                                                  Lorem Ipsum
                                            </h3>
                                        </div>
                                        <div class="framer-rof8e4"  data-framer-component-type="RichTextContainer">
                                            <p
                                               
                                                class="framer-text ghme1"
                                            >
                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="framer-1ua1v30 sectiononeb" data-framer-name="Card" id="section-4" name="Card" >
                                <div class="framer-d7t0r0">
                                    <div class="framer-dqpczu"  data-framer-component-type="RichTextContainer">
                                        <h3
                                           
                                            class="framer-text"
                                        >
                                            04
                                        </h3>
                                    </div>
                                    <div class="framer-fs6qph">
                                        <div class="framer-n6dn7u"  data-framer-component-type="RichTextContainer">
                                            <h3
                                               
                                                class="framer-text ghme1"
                                            >
                                                  Lorem Ipsum
                                            </h3>
                                        </div>
                                        <div class="framer-rof8e4"  data-framer-component-type="RichTextContainer">
                                            <p
                                               
                                                class="framer-text ghme1"
                                            >
                                               Lorem Ipsum is simply dummy text of the printing and typesetting industry
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

      <div class="framer-lubqzj container" data-framer-name="Reviews" id="reviews" name="Reviews">
    <div class="framer-tmpdlg" >
        <div class="framer-1duj7ta" data-framer-name="Card" name="Card">
            <div class="framer-1fq3ys4">
                <div class="framer-1askam5">
                    <div class="framer-oypnlo">
                        <div >
                            <img
                                decoding="async"
                                sizes="80px"
                                srcset="https://framerusercontent.com/images/qHexzd1tU85ZrnsNWebULMx4RSk.png?scale-down-to=512 512w, https://framerusercontent.com/images/qHexzd1tU85ZrnsNWebULMx4RSk.png 890w"
                                src="https://framerusercontent.com/images/qHexzd1tU85ZrnsNWebULMx4RSk.png?scale-down-to=512"
                                alt=""
                               
                            />
                        </div>
                    </div>
                    <div class="framer-gmk5zc">
                        <div class="ssr-variant hidden-v14nzn">
                            <div class="framer-11dglge"  data-framer-component-type="RichTextContainer">
                                <h3
                                    
                                    class="framer-text"
                                >
                                    Lorem Ipsum 
                                </h3>
                            </div>
                        </div>
                        <div class="framer-bz227y"  data-framer-component-type="RichTextContainer">
                            <p
                               
                                class="framer-text"
                            >
                                Lorem Ipsum is simply dummy text 
                            </p>
                        </div>
                    </div>
                </div>
                <div class="framer-1mi5571">
                    <div class="framer-1ohazg3">
                        <div
                            data-framer-component-type="SVG"
                            data-framer-name="graphic"
                            name="graphic"
                            
                            class="framer-fj5n9m rateinf3"
                            aria-hidden="true"
                        ></div>
                        <div
                            data-framer-component-type="SVG"
                            data-framer-name="graphic"
                            name="graphic"
                           
                            class="framer-7cbzvc rateinf3"
                            aria-hidden="true"
                        ></div>
                        <div
                            data-framer-component-type="SVG"
                            data-framer-name="graphic"
                            name="graphic"
                            
                            class="framer-1uik7ta rateinf3"
                            aria-hidden="true"
                        ></div>
                        <div
                            data-framer-component-type="SVG"
                            data-framer-name="graphic"
                            name="graphic"
                           
                            class="framer-1jukhlw rateinf3"
                            aria-hidden="true"
                        ></div>
                        <div
                            data-framer-component-type="SVG"
                            data-framer-name="graphic"
                            name="graphic"
                            
                            class="framer-1raveq rateinf3" 
                            aria-hidden="true"
                        ></div>
                    </div>
                    
                    <div class="framer-r48h0g"  data-framer-component-type="RichTextContainer">
                        <p
                            
                            class="framer-text"
                        >
                            Lorem Ipsum has been the industry's standard dummy text.It is a long established fact that a reader.
                        </p>
                    </div>
                </div>
            </div>
            <div class="framer-s852co">
                
                <a
                    data-framer-component-type="SVG"
                    data-framer-name="icons8_linkedin_1"
                    name="icons8_linkedin_1"
                    href="https://www.linkedin.com/in/yehor-herasymchuk/"
                    target="_blank"
                    rel="noopener"
                    
                    class="framer-1atzp7n framer-1bofbcw"
                    aria-hidden="true"
                >
                    <div class="svgContainer">
                        <svg ><use href="#svg9562390086"></use></svg>
                    </div>
                </a>
                
            </div>
        </div>
          <div class="framer-17i0gfk" data-framer-name="Card" name="Card"> 
            <div class="framer-1fq3ys4">
                <div class="framer-1askam5">
                    <div class="framer-oypnlo io">
                        <div >
                            <img
                                decoding="async"
                                sizes="80px"
                               
                                src="https://framerusercontent.com/images/IvYaXZtEvem5EeIVIHprzMgDIEs.png?scale-down-to=512"
                                alt=""
                               
                            />
                        </div>
                    </div>
                    <div class="framer-gmk5zc">
                        <div class="ssr-variant hidden-v14nzn">
                            <div class="framer-11dglge"  data-framer-component-type="RichTextContainer">
                                <h3
                                    
                                    class="framer-text"
                                >
                                     Lorem Ipsum 
                                </h3>
                            </div>
                        </div>
                        <div class="framer-bz227y"  data-framer-component-type="RichTextContainer">
                            <p
                               
                                class="framer-text"
                            >
                                  Lorem Ipsum has been the industry's standard dummy text.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="framer-1mi5571">
                    <div class="framer-1ohazg3">
                        <div
                            data-framer-component-type="SVG"
                            data-framer-name="graphic"
                            name="graphic"
                            
                            class="framer-fj5n9m rateinf3"
                            aria-hidden="true"
                        ></div>
                        <div
                            data-framer-component-type="SVG"
                            data-framer-name="graphic"
                            name="graphic"
                           
                            class="framer-7cbzvc rateinf3"
                            aria-hidden="true"
                        ></div>
                        <div
                            data-framer-component-type="SVG"
                            data-framer-name="graphic"
                            name="graphic"
                            
                            class="framer-1uik7ta rateinf3"
                            aria-hidden="true"
                        ></div>
                        <div
                            data-framer-component-type="SVG"
                            data-framer-name="graphic"
                            name="graphic"
                           
                            class="framer-1jukhlw rateinf3"
                            aria-hidden="true"
                        ></div>
                        <div
                            data-framer-component-type="SVG"
                            data-framer-name="graphic"
                            name="graphic"
                            
                            class="framer-1raveq rateinf3"
                            aria-hidden="true"
                        ></div>
                    </div>
                    
                    <div class="framer-r48h0g"  data-framer-component-type="RichTextContainer">
                        <p
                            
                            class="framer-text"
                        >
                            Lorem Ipsum has been the industry's standard dummy text.It is a long established fact that a reader.
                        </p>
                    </div>
                </div>
            </div>
            <div class="framer-s852co">
                
                <a
                    data-framer-component-type="SVG"
                    data-framer-name="icons8_linkedin_1"
                    name="icons8_linkedin_1"
                    href="#"
                    target="_blank"
                    rel="noopener"
                    
                    class="framer-1atzp7n framer-1bofbcw"
                    aria-hidden="true"
                >
                    <div class="svgContainer">
                        <svg ><use href="#svg9562390086"></use></svg>
                    </div>
                </a>
                
            </div>
        </div>

         <div class="framer-17i0gfk" data-framer-name="Card" name="Card"> 
            <div class="framer-1fq3ys4">
                <div class="framer-1askam5">
                    <div class="framer-oypnlo io">
                        <div >
                            <img
                                decoding="async"
                                sizes="80px"
                               
                                src="https://framerusercontent.com/images/IvYaXZtEvem5EeIVIHprzMgDIEs.png?scale-down-to=512"
                                alt=""
                               
                            />
                        </div>
                    </div>
                    <div class="framer-gmk5zc">
                        <div class="ssr-variant hidden-v14nzn">
                            <div class="framer-11dglge"  data-framer-component-type="RichTextContainer">
                                <h3
                                    
                                    class="framer-text"
                                >
                                   Lorem Ipsum
                                </h3>
                            </div>
                        </div>
                        <div class="framer-bz227y"  data-framer-component-type="RichTextContainer">
                            <p
                               
                                class="framer-text"
                            >
                                11 January 2023
                            </p>
                        </div>
                    </div>
                </div>
                <div class="framer-1mi5571">
                    <div class="framer-1ohazg3">
                        <div
                            data-framer-component-type="SVG"
                            data-framer-name="graphic"
                            name="graphic"
                            
                            class="framer-fj5n9m rateinf3"
                            aria-hidden="true"
                        ></div>
                        <div
                            data-framer-component-type="SVG"
                            data-framer-name="graphic"
                            name="graphic"
                           
                            class="framer-7cbzvc rateinf3"
                            aria-hidden="true"
                        ></div>
                        <div
                            data-framer-component-type="SVG"
                            data-framer-name="graphic"
                            name="graphic"
                            
                            class="framer-1uik7ta rateinf3"
                            aria-hidden="true"
                        ></div>
                        <div
                            data-framer-component-type="SVG"
                            data-framer-name="graphic"
                            name="graphic"
                           
                            class="framer-1jukhlw rateinf3"
                            aria-hidden="true"
                        ></div>
                        <div
                            data-framer-component-type="SVG"
                            data-framer-name="graphic"
                            name="graphic"
                            
                            class="framer-1raveq rateinf3"
                            aria-hidden="true"
                        ></div>
                    </div>
                    <div class="framer-1m1n3de"  data-framer-component-type="RichTextContainer">
                        <p
                            
                            class="framer-text"
                        >
                         Lorem Ipsum has been the industry's standard dummy text.It is a long established fact that a reader.
                        </p>
                    </div>
                   
                </div>
            </div>
            <div class="framer-s852co">
                
                <a
                    data-framer-component-type="SVG"
                    data-framer-name="icons8_linkedin_1"
                    name="icons8_linkedin_1"
                    href="#"
                    target="_blank"
                    rel="noopener"
                    
                    class="framer-1atzp7n framer-1bofbcw"
                    aria-hidden="true"
                >
                    <div class="svgContainer">
                        <svg ><use href="#svg9562390086"></use></svg>
                    </div>
                </a>
                
            </div>
        </div>
        </div></div>

      <div class="connecting-trends first-trends last">
         <div class="container">
            <div class="row align-items-center rotate-in-mobile">
            <div class="col-md-6">
            <Trends />
                     </div>
            
            
            <div class="col-md-6">
               <div class="section-content">
               <h2 class="heading-3"> <span class="eyebrow">Lorem Ipsum</span> 
               </h2>
                <h2>Lorem Ipsum <br/>is dummy text.</h2>
                  
                  
                <div class="upperd">
                     <p class="cta-link link"><a href="/signup" class="btn-link">Initiate Consultation&nbsp; </a></p>
                     </div>
               </div> </div>
            </div>

            </div>
      </div>
      </div> 
      



<div id="Get-Started" class="flex-section bubble-top-left">
    <div class="container designerscroll">
        <div class="productcontent _120pxpadding">
           
           	<div class="faq-bottom">
    <div class="faq-title"><h2 class="heading-3 bo"> <span class="eyebrow">FAQ</span></h2>
    <br/>
    <p>All you need to know about cloud savings and our cloud optimization platform</p>
    </div>
    <div class="faq-item">
        <div class="faq--item ">
            <div class="faq-question ">
                <h5 class="h5-faq" >How does Royalty Tax generate additional savings on GCP?</h5>
                  <img
                    src="/assets/images/add_plus_icon.png"
                    loading="lazy"
                    alt=""
                    class="faq-icon plus-c"
                    
                />
                 <img
                    src="/assets/images/minus_icon.png"
                    loading="lazy"
                    alt=""
                    class="faq-icon minus-c"
                    
                />
            </div>
            <div class="faq-answer faq-none" >
                <p>Royalty Tax applies Reserved Instances and Savings Plans at the billing organization level to the bills we manage and pay on your behalf. By overseeing a large portfolio of clients, we also unlock access to volume discounts. This combination allows us to significantly reduce your GCP costs while ensuring you benefit from the best available rates.
All with no rigid, long-term contracts.</p>
            </div>
        </div>
        <div class="faq--item ">
            <div class="faq-question">
                <h5 class="h5-faq" >Is any DevOps work required?</h5>
                <img
                    src="/assets/images/add_plus_icon.png"
                    loading="lazy"
                    alt=""
                    class="faq-icon plus-c"
                    
                />
                 <img
                    src="/assets/images/minus_icon.png"
                    loading="lazy"
                    alt=""
                    class="faq-icon minus-c"
                    
                />
            </div>
            <div class="faq-answer faq-none" >
                <p>No DevOps work is needed from your team, except for the initial step of connecting your account. Our process is seamless and non-intrusive, ensuring that no changes are made to your existing infrastructure.</p>
            </div>
        </div>
        <div class="faq--item ">
            <div class="faq-question">
                <h5 class="h5-faq" >What level of access does Royalty Tax get to my GCP account?</h5>
                <img
                    src="/assets/images/add_plus_icon.png"
                    loading="lazy"
                    alt=""
                    class="faq-icon plus-c"
                    
                />
                 <img
                    src="/assets/images/minus_icon.png"
                    loading="lazy"
                    alt=""
                    class="faq-icon minus-c"
                    
                />
            </div>
            <div class="faq-answer faq-none" >
                <p>Royalty Tax is granted read-only access to your GCP billing information. This means we can view your billing details but cannot access your data or make any changes to your infrastructure, such as creating or removing users, or launching or shutting down instances. Our access is strictly limited to protect your security and privacy.</p>
            </div>
        </div>
        <div class="faq--item ">
            <div class="faq-question">
                <h5 class="h5-faq" >What happens to our account if Royalty Tax shuts down?</h5>
                <img
                    src="/assets/images/add_plus_icon.png"
                    loading="lazy"
                    alt=""
                    class="faq-icon plus-c"
                    
                />
                 <img
                    src="/assets/images/minus_icon.png"
                    loading="lazy"
                    alt=""
                    class="faq-icon minus-c"
                    
                />
            </div>
            <div class="faq-answer faq-none" >
                <p>In the unlikely event that Royalty Tax shuts down, your account will be disconnected from the Royalty Tax billing organization and revert to a standalone account. From the date of disconnection, you will only be responsible for managing and paying new cloud bills, while any existing debt will remain with Royalty Tax.</p>
            </div>
        </div>
        <div class="faq--item ">
            <div class="faq-question">
                <h5 class="h5-faq" >How can we secure our project and client data?</h5>
                <img
                    src="/assets/images/add_plus_icon.png"
                    loading="lazy"
                    alt=""
                    class="faq-icon plus-c"
                    
                />
                 <img
                    src="/assets/images/minus_icon.png"
                    loading="lazy"
                    alt=""
                    class="faq-icon minus-c"
                    
                />
            </div>
            <div class="faq-answer faq-none" >
                <p>Connecting your account to Royalty Tax does not require sharing any project or client details.<br/>
This minimal data requirement ensures that your sensitive information remains secure and confidential.</p>
            </div>
        </div>

         <div class="faq--item ">
            <div class="faq-question">
                <h5 class="h5-faq" >What payment methods can I use?</h5>
                <img
                    src="/assets/images/add_plus_icon.png"
                    loading="lazy"
                    alt=""
                    class="faq-icon plus-c"
                    
                />
                 <img
                    src="/assets/images/minus_icon.png"
                    loading="lazy"
                    alt=""
                    class="faq-icon minus-c"
                    
                />
            </div>
            <div class="faq-answer faq-none" >
                <p>Royalty Tax supports a variety of payment methods, including credit cards, Crypto, SEPA, ACH, and Wire transfers. You can choose the payment option that best suits your financial management needs, offering flexibility and convenience for your GCP billing.</p>
            </div>
        </div>
         <div class="faq--item ">
            <div class="faq-question">
                <h5 class="h5-faq" >What type of support do you offer?</h5>
                <img
                    src="/assets/images/add_plus_icon.png"
                    loading="lazy"
                    alt=""
                    class="faq-icon plus-c"
                    
                />
                 <img
                    src="/assets/images/minus_icon.png"
                    loading="lazy"
                    alt=""
                    class="faq-icon minus-c"
                    
                />
            </div>
            <div class="faq-answer faq-none" >
                <p>Royalty Tax provides comprehensive support through multiple channels. Our dedicated FinOps team is available to assist you with any billing, optimization, or account-related queries. We offer support via email, chat, and scheduled calls, ensuring you have access to expert guidance whenever you need it.</p>
            </div>
        </div>
       
    </div>
</div>
        </div>
    </div>
    <img src="/assets/images/home4/602f1896fb75bb414460c109_Shape-3.svg" loading="lazy" width="350" alt="" class="absolutebg-40 uiop"></img>
</div>


<div class="flex-section dark-gradient">
    <div class="container designerscroll">
        <div class="productcontent _96pxpadding _64pxbottom">
        <div class="gradientext-wrapper"><h2 class="designertab-h2 hometopzero">Lorem Ipsum</h2></div>
            <h2 class="bigheading whitebig-h1">Lorem Ipsum is simply dummy text</h2>
            <p class="designer-p1 designhero whitep1">Lorem Ipsum has been the industry's standard dummy text</p>
            <div
                data-w-id="5ebf2626-b437-da90-cfbe-0c8a0a751c02 vbf"
               
                class="buildanimation-wrapper home"
            >
                <div class="buildanimationimage-big">
                    <img src="https://global-uploads.webflow.com/5bddf05642686caf6d17eb58/5ffd06cc53b7aad5b5efdcb2_5fe2541860b0fea1137a9f73_VF_Block.svg" loading="lazy" alt="Voiceflow Logo Connector for APIs" />
                </div>
                <div data-w-id="5ebf2626-b437-da90-cfbe-0c8a0a751c05" class="pulsebig njh"></div>
                <div data-w-id="5ebf2626-b437-da90-cfbe-0c8a0a751c06" class="pulsesmall njh"></div>
                <img
                    src="https://global-uploads.webflow.com/5bddf05642686caf6d17eb58/602f27e0e683eb019bf0655c_Lines.png"
                    loading="lazy"
                    width="560"
                    sizes="100vw"
                    srcset="
                        https://global-uploads.webflow.com/5bddf05642686caf6d17eb58/602f27e0e683eb019bf0655c_Lines-p-500.png   500w,
                        https://global-uploads.webflow.com/5bddf05642686caf6d17eb58/602f27e0e683eb019bf0655c_Lines-p-800.png   800w,
                        https://global-uploads.webflow.com/5bddf05642686caf6d17eb58/602f27e0e683eb019bf0655c_Lines-p-1080.png 1080w,
                        https://global-uploads.webflow.com/5bddf05642686caf6d17eb58/602f27e0e683eb019bf0655c_Lines.png        1120w
                    "
                    alt=""
                    class="lines"
                />
                <img
                    src="https://global-uploads.webflow.com/5bddf05642686caf6d17eb58/5ffd19cd4e4d8657ca942587_Path%20Copy.png"
                    loading="lazy"
                    sizes="100vw"
                    srcset="
                        https://global-uploads.webflow.com/5bddf05642686caf6d17eb58/5ffd19cd4e4d8657ca942587_Path%20Copy-p-500.png   500w,
                        https://global-uploads.webflow.com/5bddf05642686caf6d17eb58/5ffd19cd4e4d8657ca942587_Path%20Copy-p-800.png   800w,
                        https://global-uploads.webflow.com/5bddf05642686caf6d17eb58/5ffd19cd4e4d8657ca942587_Path%20Copy-p-1080.png 1080w,
                        https://global-uploads.webflow.com/5bddf05642686caf6d17eb58/5ffd19cd4e4d8657ca942587_Path%20Copy.png        1125w
                    "
                    alt=""
                    class="blurtop"
                />
            </div>
            <div class="iopfotter">
            <div class="container">
                <div class="row flex-row row-footer-nav justify-content-start justify-content-md-between">
                    <div class="col col-12 col-lg-4 col-xl-3 my-1 order-last order-lg-first">
                        <div class="organization py-2 py-md-1 py-lg-0" >
                            <meta itemprop="name" content="BP" />
                            <figure class="logo">
                               
                                <a aria-current="page" class="active" href="/">
                                <img src="/assets/images/home4/logo_home.png" class="desklogo fotter" />
                                </a>
                               
                            </figure>
                            <div>
                                
                                <div id="choice-footer-msg" class="choice-footer-msg ccpa-msg-added newb">
                                Lorem Ipsum is simply
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    

                    <div class="col col-12 col-lg-8 my-1 order-first order-lg-last">
                        <div class="row">

                          

                            <div class="col col-6 col-sm-3 mb-3 firstul" > 
                                <div class="footer-nav-wrapper py-2 py-md-1 py-lg-0" id="footer_resources_navigation">
                                    <div class="nav-header">
                                        Resources
                                    </div>
                                    <div class="menu-footer-resources-navigation-container">
                                        <ul id="menu-footer-resources-navigation" class="d-flex flex-column" >
                                            <li id="menu-item-337" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-337">
                                            <a href="#">Blog</a>
                                            </li>
                                            
                                            
                                            <li id="menu-item-63305" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-63305">
                                            <a href="#">Data Insights</a>
                                            </li>
                                           
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="col col-6 col-sm-6 mb-3">
                                <div class="footer-nav-wrapper py-2 py-md-1 py-lg-0" id="footer_solutions_navigation">
                                    <div class="nav-header">
                                    Access
                                    </div>
                                    <div class="menu-footer-solutions-container">
                                        <ul id="menu-footer-solutions" class=" d-flex flex-column" >
                                           <li id="menu-item-49409" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-49409">
                                           
                                              
                                                 <a href="/signup" >Get Started</a>
                                            </li>
                                            <li id="menu-item-49410" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-49410">
                                            
                                                <NavLink to="/workpoint/login">Sign In</NavLink>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="col col-6 col-sm-6 col-lg-3">
                                <div class="footer-nav-wrapper py-2 py-md-1 py-lg-0" id="footer_company_navigation">
                                    <div class="nav-header">
                                        Company
                                    </div>
                                    <div class="menu-footer-company-navigation-container">
                                        <ul id="menu-footer-company-navigation" class=" d-flex flex-column" >
                                            <li id="menu-item-47827" class="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-47825 current_page_item menu-item-47827">
                                            
                                            <NavLink to="/services">Services</NavLink>
                                            </li>
                                            
                                            <li id="menu-item-47827" class="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-47825 current_page_item menu-item-47827">
                                            
                                            <NavLink to="/testimonials">Testimonials</NavLink>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                          
                        </div>
                    </div>
                </div>
            </div>
            </div> </div>
    </div>
</div>

   

<div class="row -center hero-row popupde">
    <div class="close2">
        <a class="close-atag" href="javascript:void(0);">
            <img src="/assets/images/xlose.png" />
        </a>
    </div>
<img class="feature-callout__img" src="https://luna1.co/005100.png" alt="Image of team" width="112" />
            <div class="signupForm-homepage-center col-xsmall-12 col-small-8 col-small-offset-2 col-medium-10 col-medium-offset-1 col-large-8 col-large-offset-2 col-xlarge-6 col-xlarge-offset-3">
                <h4 class="heading -spacing-2 -h3">Sign up</h4>
                <p class="subheading -spacing-1 hero-legal-copy">
                    By signing up, I agree to the Asana <a href="/terms#privacy-policy" class="css-1kfjk3x-Link">Privacy Policy</a> and <a href="/terms#terms-of-service" class="css-1kfjk3x-Link">Terms of Service</a>.
                </p>
                <form action="get-started/verify" method="get" novalidate="" class="signupForm hidden-logged-in signupForm-homepage-center" __bizdiag="1239199729" __biza="WJ__">
                    <div class="signupForm-container">
                        <div class="signupForm-row">
                        <div class="form-group">
                                
                                <input type="email" name="e" required  class="form-control" placeholder="name@company.com" id="work-email" />
                            </div>

                            <div class="submit-btn">
                                <button id="go-to-nextStep"  onClick={this.toggleBox} class="btn btn-lg btn-flat btn-block btn-primary">Next</button>
                            </div>
                        </div>
                    </div>
                </form>
                
            </div>
        </div>
    </div>
    );
}
}

export default Blockproducers;
