import React from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";


import Error from "../pages/error";

// components
import Layout from "./Layout";

import NotFoundPage from "../pages/404";
import SigninPage from "../pages/signinnew";
import SigninPage2 from "../pages/signin";
import MainPage from "../pages/mainhome";
import AboutPage from "../pages/about";
import Signup from "../pages/signup-workpoint";
import Intelligence from "../pages/Intelligence";
import Prospecting from "../pages/Prospecting";
import Services from "../pages/Services";
import Testimonials from "../pages/Testimonials";
import Home2 from "../pages/home2";
import Home3 from "../pages/home3";
import SignupRoyalty from "../pages/signup-Royalty";
import serviceIncorporation from "../pages/incorporation";
import servicebookkeeping from "../pages/bookkeeping";
import servicetaxPlanning from "../pages/taxPlanning";
import servicetaxReturns from "../pages/taxReturns";
import servicefinancialSystems from "../pages/financialSystems";
// context
import { useUserState } from "../context/UserContext";

export default function App() {
  // global
  var { isAuthenticated } = useUserState();

  return (
    <Router>
      <Switch>
         
           <Route exact path="/"  component={Home3}></Route> 
           <Route exact path="/services"  component={Services}></Route> 
            <Route exact path="/testimonials"  component={Testimonials}></Route> 
             <Route exact path="/login"  component={SigninPage}></Route>
             <Route exact path="/signup"  component={SignupRoyalty}></Route>
             <Route exact path="/service/incorporation"  component={serviceIncorporation}></Route>
             <Route exact path="/service/bookkeeping"  component={servicebookkeeping}></Route>
             <Route exact path="/service/taxPlanning"  component={servicetaxPlanning}></Route>
             <Route exact path="/service/taxReturns"  component={servicetaxReturns}></Route>
             <Route exact path="/service/financialSystems"  component={servicefinancialSystems}></Route>
          
        
         <Route component={Error} />
      </Switch>
    </Router>
  );
 // #######################################################################

  function PrivateRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props =>
          isAuthenticated ? (
            React.createElement(component, props)
          ) : (
            <Redirect
              to={{
                pathname: "/workpoint",
                state: {
                  from: props.location,
                },
              }}
            />
          )
        }
      />
    );
  }

  function PublicRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props =>
          isAuthenticated ? (
            <Redirect
              to={{
                pathname: "/",
              }}
            />
          ) : (
            React.createElement(component, props)
          )
        }
      />
    );
  }
 
}
