import React,{Component} from 'react';
import { NavLink } from 'react-router-dom';
import Trends from './partials/Trends';
//import './home4.css';


class Workpoint extends React.Component {
   componentWillMount () {
    
    const link  = document.createElement('link');
    
    link.rel  = 'stylesheet';
    link.type = 'text/css';
    link.href = '/assets/styles/home4.css';
    
    //document.head.appendChild(link);
   
     // document.head.appendChild(css);

      const script1 = document.createElement("script");
   
     // script1.src = "/assets/js/slick.min.js";
      script1.src = "/assets/js/main.js?v=1";
      script1.async = true;
   
      document.body.appendChild(script1);
    }
   render(){
 
    return(
        <div class="gmxpage block newpagedesign home4 homenewdesignload">
             <div class="designerhero-wrapper">
        <div
            data-w-id="1ed3267b-03c2-d180-b17d-3e3017535aa9"
            
            class="herobackround-absolute team"
        ></div>
        <div class="flex-section navbar">
            <div data-collapse="medium" data-animation="default" data-duration="800" data-easing="ease-in-cubic" data-easing2="ease-out-quart" role="banner" class="navbarnew w-nav">
                <nav class="nav-container w-container">
                <a href="/" class="nav-logo w-inline-block"><img src="/assets/images/home4/royalty_tax_black.png" width="250" alt="" class="logo-5" /></a>
                    <nav role="navigation" class="navmenulinks w-nav-menu">
                        <div class="nav-menu homehidden">
                            <div data-hover="true" data-delay="0" class="dropdown w-dropdown">
                                <div class="navlink-dropdown w-dropdown-toggle" id="w-dropdown-toggle-0" aria-controls="w-dropdown-list-0" aria-haspopup="menu" aria-expanded="false" role="button" tabindex="0">
                                    <div class="chevrondown w-icon-dropdown-toggle" aria-hidden="true"></div>
                                    <div class="text-block">By Role</div>
                                </div>
                                <nav class="dropdown-list w-dropdown-list" id="w-dropdown-list-0" aria-labelledby="w-dropdown-toggle-0">
                                    <div class="dropdownnavlinks">
                                        <a href="/designer" class="dropdownlink w-dropdown-link" tabindex="0">For Designers</a><a href="/teams" aria-current="page" class="dropdownlink w-dropdown-link w--current" tabindex="0">For Teams</a>
                                        <a href="/developer" class="dropdownlink w-dropdown-link" tabindex="0">For Developers</a>
                                    </div>
                                </nav>
                            </div>
                            <div data-hover="true" data-delay="0" class="dropdown hide w-dropdown">
                                <div class="navlink-dropdown w-dropdown-toggle" id="w-dropdown-toggle-1" aria-controls="w-dropdown-list-1" aria-haspopup="menu" aria-expanded="false" role="button" tabindex="0">
                                    <div class="chevrondown w-icon-dropdown-toggle" aria-hidden="true"></div>
                                    <div class="text-block">By Channel</div>
                                </div>
                                <nav class="dropdown-list w-dropdown-list" id="w-dropdown-list-1" aria-labelledby="w-dropdown-toggle-1">
                                    <div class="dropdownnavlinks">
                                        <a href="/create-alexa-skill" class="dropdownlink w-dropdown-link" tabindex="0">Alexa Skills</a><a href="/create-google-action" class="dropdownlink w-dropdown-link" tabindex="0">Google Actions</a>
                                        <a href="/ivr-builder" class="dropdownlink w-dropdown-link" tabindex="0">IVR's</a><a href="/chatbots" class="dropdownlink w-dropdown-link" tabindex="0">Chatbots</a>
                                        <a href="/in-car-assistant" class="dropdownlink w-dropdown-link" tabindex="0">In-car Assistants</a><a href="/in-app-assistant" class="dropdownlink w-dropdown-link" tabindex="0">In-app Assistants</a>
                                    </div>
                                </nav>
                            </div>
                            <div data-hover="true" data-delay="0" class="dropdown w-dropdown">
                                <div class="navlink-dropdown w-dropdown-toggle" id="w-dropdown-toggle-2" aria-controls="w-dropdown-list-2" aria-haspopup="menu" aria-expanded="false" role="button" tabindex="0">
                                    <div class="chevrondown w-icon-dropdown-toggle" aria-hidden="true"></div>
                                    <div class="text-block">Resources</div>
                                </div>
                                <nav class="dropdown-list w-dropdown-list" id="w-dropdown-list-2" aria-labelledby="w-dropdown-toggle-2">
                                    <div class="dropdownnavlinks">
                                        <a href="/docs" class="dropdownlink w-dropdown-link" tabindex="0">Documentation</a><a href="/tutorials" class="dropdownlink w-dropdown-link" tabindex="0">Tutorials</a>
                                        <a href="/community" class="dropdownlink w-dropdown-link" tabindex="0">Community</a><a href="/events" class="dropdownlink w-dropdown-link" tabindex="0">Events</a>
                                        <a href="/blog" class="dropdownlink w-dropdown-link" tabindex="0">Blog</a><a href="/templates" class="dropdownlink w-dropdown-link" tabindex="0">Templates</a>
                                        <a href="/resources" class="dropdownlink w-dropdown-link" tabindex="0">Resources</a><a href="/jobs" class="dropdownlink w-dropdown-link" tabindex="0">CxD Jobs</a>
                                    </div>
                                </nav>
                            </div>
                           
                           
                        </div>
                        <div class="navcta">
                            <a href="/" class="navlink-button sign-in w-nav-link">Home</a>
                                <NavLink to="/services" class="navlink-button sign-in w-nav-link">Services</NavLink>
                                <NavLink to="/testimonials" class="navlink-button sign-in w-nav-link">Testimonials</NavLink> 
                                <NavLink to="/login" class="navlink-button sign-in w-nav-link">Log In</NavLink>
                                 <a href="/signup" class="btn-lg btn-flat btn-outline ml-3 desk getnewhome innergetatag" >Get Started</a>
                               
                            
                        </div>
                    </nav>
                    <div
                        data-w-id="0fa4f9ae-b612-ecd9-13c7-445e7a58764a"
                        class="menu-button w-nav-button"
                        
                        aria-label="menu"
                        role="button"
                        tabindex="0"
                        aria-controls="w-nav-overlay-0"
                        aria-haspopup="menu"
                        aria-expanded="false"
                    >
                        <img src="https://global-uploads.webflow.com/5bddf05642686caf6d17eb58/5fd6310ed7b9bf44c616bd44_Menu%20Icon.svg" loading="lazy" width="24" alt="" class="menu-icon" />
                        <div class="menuline-top"></div>
                        <div class="menuline-top-center"></div>
                        <div class="menuline-top-bottom"></div>
                    </div>
                </nav>
                <div class="w-nav-overlay" data-wf-ignore="" id="w-nav-overlay-0"></div>
            </div>
        </div>
        <div class="flex-section producthero">
            <div class="container designhero">
                <div
                    data-w-id="0b775e4e-f326-f2ac-3514-62eaf225eae5 zs"
                    
                    class="heroheading-wrapper"
                >
                    <div class="contentrow aligncenternopadding">
                        <h1 class="bigheading marginzero">
                        Lorem Ipsum is simply dummy  
                        </h1>
                    </div>
                </div>
                <p
                    data-w-id="95d2c3ff-3fee-8f44-0725-8ccbe3f6177f zs"
                    
                    class="designer-p1 designhero"
                >
                   Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                </p>
               
            </div>
        </div>
    </div>
   
             <div class="logodiv flex-section channels">
                    <h4 class="build">Lorem Ipsum</h4>
                    <div className='workAuto'>
                  <div class="row align-items-center">
                     <div class="col-md-4">
                        <h6>What is Lorem Ipsum</h6>
                    <p>Lorem Ipsum is simply dummy text of the printing .</p>
                     </div>
                     <div class="col-md-4">
                        <h6>What is Lorem Ipsum</h6>
                    <p>Lorem Ipsum is simply dummy text of the printing .</p>
                     </div>
                     <div class="col-md-4">
                        <h6>What is Lorem Ipsum</h6>
                    <p>Lorem Ipsum is simply dummy text of the printing .</p>
                     </div>
                  </div>
                  </div>
                   
                   
                

                
                <img src="https://global-uploads.webflow.com/5bddf05642686caf6d17eb58/602f1896fb75bb414460c109_Shape-3.svg" loading="lazy" width="350" alt="" class="absolutebg-40" />
                </div>
                
                <div class="cms-wrapper wf-section resources-sec">
    <div class="cms-content-wrapper">
        
        <div class="collection-list-wrapper w-dyn-list">
            <div role="list" class="grid-col-1 gap-40px w-dyn-items">
                <div role="listitem" class="w-dyn-item">
                    <div class="card-pro resources-collection">
                        <div class="resources-collection-image_wrapper"> 
                            <img
                                src="/assets/images/simple_test_image.jpg"
                                
                                class="resources-collection-image"
                            />
                        </div>
                        <div class="resources-collection-content">
                            
                            <h2 class="cms-heading margin-top-0px">Incorporation</h2>
                            <div class="pro-paragraph sm">
                            <p>Is incorporation the way to go? So, you’re ready to make your big idea or side hustle into an actual business. We’ve been there, and we know that the process to formally incorporate your business can be filled with seemingly complicated legal jargon and ongoing compliance hurdles...
                            <a class="readmore" href='/service/incorporation'>Read More</a>
                            </p>

                            </div>
                            
                        </div>
                    </div>
                </div>
                <div role="listitem" class="w-dyn-item">
                    <div class="card-pro resources-collection">
                       <div class="resources-collection-image_wrapper"> 
                            <img
                                src="/assets/images/simple_test_image.jpg"
                                
                                class="resources-collection-image"
                            />
                        </div>
                        <div class="resources-collection-content">
                           
                           <h2 class="cms-heading margin-top-0px">Bookkeeping</h2>
                            <div class="pro-paragraph sm">
                                <p>Bookkeeping is the unsung star of the financial planning world. So while it may sound simple, it’s essential and quite liberating to you in facilitating your business to do it right.  If you don’t know the financial transactions going on in your business, how do you expect to increase...  <a class="readmore" href='/service/bookkeeping'>Read More</a></p>
                           
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div role="listitem" class="w-dyn-item">
                    <div class="card-pro resources-collection">
                       <div class="resources-collection-image_wrapper"> 
                            <img
                                src="/assets/images/simple_test_image.jpg"
                                
                                class="resources-collection-image"
                            />
                        </div>
                        <div class="resources-collection-content">
                            
                           <h2 class="cms-heading margin-top-0px">Tax Planning</h2>
                            <div class="pro-paragraph sm">
                                <p>There is quite a bit of work that goes into proper tax planning. To make things simple. It’s the process of anticipating your tax liability and determining legal and acceptable ways to lower it...<a class="readmore" href='/service/taxPlanning'>Read More</a></p>
                                      </div>
                          
                        </div>
                    </div>
                </div>
                <div role="listitem" class="w-dyn-item">
                    <div class="card-pro resources-collection">
                       <div class="resources-collection-image_wrapper"> 
                            <img
                                src="/assets/images/simple_test_image.jpg"
                                
                                class="resources-collection-image"
                            />
                        </div>
                        <div class="resources-collection-content">
                           
                           <h2 class="cms-heading margin-top-0px">Tax Returns</h2>
                            <div class="pro-paragraph sm">
                                <p>While most people dread Tax Day, we live for it. Tax returns are the most wonderful time of the year or something like that. Whether you need help with IRS audits, notices and forms, back taxes, or simply need help filing, we’re passionate about finding tax solutions for even the most...<a class="readmore" href='/service/taxReturns'>Read More</a></p>
                                </div>
                            
                        </div>
                    </div>
                </div>
                <div role="listitem" class="w-dyn-item">
                    <div class="card-pro resources-collection">
                        <div class="resources-collection-image_wrapper"> 
                            <img
                                src="/assets/images/simple_test_image.jpg"
                                
                                class="resources-collection-image"
                            />
                        </div>
                        <div class="resources-collection-content">
                           
                           <h2 class="cms-heading margin-top-0px">Financial Systems</h2>
                            <div class="pro-paragraph sm">
                                <p>The best businesses run on efficient financial systems. For example, helpful tools such as automation, different types of transferring services, and cash flow tricks of the trade will make an immense difference in how smoothly your business runs...<a class="readmore" href='/service/financialSystems'>Read More</a></p>
                             </div>
                            
                        </div>
                    </div>
                </div>
               
            </div>
        </div>
    </div>
</div>






<div class="flex-section dark-gradient">
    <div class="container designerscroll">
        <div class="productcontent _96pxpadding _64pxbottom">
        <div class="gradientext-wrapper"><h2 class="designertab-h2 hometopzero">Lorem Ipsum</h2></div>
            <h2 class="bigheading whitebig-h1">Lorem Ipsum is simply dummy text</h2>
            <p class="designer-p1 designhero whitep1">Lorem Ipsum has been the industry's standard dummy text</p>
            <div
                data-w-id="5ebf2626-b437-da90-cfbe-0c8a0a751c02 vbf"
               
                class="buildanimation-wrapper home"
            >
                <div class="buildanimationimage-big">
                    <img src="https://global-uploads.webflow.com/5bddf05642686caf6d17eb58/5ffd06cc53b7aad5b5efdcb2_5fe2541860b0fea1137a9f73_VF_Block.svg" loading="lazy" alt="Voiceflow Logo Connector for APIs" />
                </div>
                <div data-w-id="5ebf2626-b437-da90-cfbe-0c8a0a751c05" class="pulsebig njh"></div>
                <div data-w-id="5ebf2626-b437-da90-cfbe-0c8a0a751c06" class="pulsesmall njh"></div>
                <img
                    src="https://global-uploads.webflow.com/5bddf05642686caf6d17eb58/602f27e0e683eb019bf0655c_Lines.png"
                    loading="lazy"
                    width="560"
                    sizes="100vw"
                    srcset="
                        https://global-uploads.webflow.com/5bddf05642686caf6d17eb58/602f27e0e683eb019bf0655c_Lines-p-500.png   500w,
                        https://global-uploads.webflow.com/5bddf05642686caf6d17eb58/602f27e0e683eb019bf0655c_Lines-p-800.png   800w,
                        https://global-uploads.webflow.com/5bddf05642686caf6d17eb58/602f27e0e683eb019bf0655c_Lines-p-1080.png 1080w,
                        https://global-uploads.webflow.com/5bddf05642686caf6d17eb58/602f27e0e683eb019bf0655c_Lines.png        1120w
                    "
                    alt=""
                    class="lines"
                />
                <img
                    src="https://global-uploads.webflow.com/5bddf05642686caf6d17eb58/5ffd19cd4e4d8657ca942587_Path%20Copy.png"
                    loading="lazy"
                    sizes="100vw"
                    srcset="
                        https://global-uploads.webflow.com/5bddf05642686caf6d17eb58/5ffd19cd4e4d8657ca942587_Path%20Copy-p-500.png   500w,
                        https://global-uploads.webflow.com/5bddf05642686caf6d17eb58/5ffd19cd4e4d8657ca942587_Path%20Copy-p-800.png   800w,
                        https://global-uploads.webflow.com/5bddf05642686caf6d17eb58/5ffd19cd4e4d8657ca942587_Path%20Copy-p-1080.png 1080w,
                        https://global-uploads.webflow.com/5bddf05642686caf6d17eb58/5ffd19cd4e4d8657ca942587_Path%20Copy.png        1125w
                    "
                    alt=""
                    class="blurtop"
                />
            </div>
            <div class="iopfotter">
            <div class="container">
                <div class="row flex-row row-footer-nav justify-content-start justify-content-md-between">
                    <div class="col col-12 col-lg-4 col-xl-3 my-1 order-last order-lg-first">
                        <div class="organization py-2 py-md-1 py-lg-0" >
                            <meta itemprop="name" content="BP" />
                            <figure class="logo">
                               
                                <a aria-current="page" class="active" href="/">
                                <img src="/assets/images/home4/logo_home.png" class="desklogo fotter" />
                                </a>
                               
                            </figure>
                            <div>
                                
                                <div id="choice-footer-msg" class="choice-footer-msg ccpa-msg-added newb">
                                Lorem Ipsum is simply
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    

                    <div class="col col-12 col-lg-8 my-1 order-first order-lg-last">
                        <div class="row">

                          

                            <div class="col col-6 col-sm-3 mb-3 firstul" > 
                                <div class="footer-nav-wrapper py-2 py-md-1 py-lg-0" id="footer_resources_navigation">
                                    <div class="nav-header">
                                        Resources
                                    </div>
                                    <div class="menu-footer-resources-navigation-container">
                                        <ul id="menu-footer-resources-navigation" class="d-flex flex-column" >
                                            <li id="menu-item-337" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-337">
                                            <a href="#">Blog</a>
                                            </li>
                                            
                                            
                                            <li id="menu-item-63305" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-63305">
                                            <a href="#">Data Insights</a>
                                            </li>
                                           
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="col col-6 col-sm-6 mb-3">
                                <div class="footer-nav-wrapper py-2 py-md-1 py-lg-0" id="footer_solutions_navigation">
                                    <div class="nav-header">
                                    Access
                                    </div>
                                    <div class="menu-footer-solutions-container">
                                        <ul id="menu-footer-solutions" class=" d-flex flex-column" >
                                           <li id="menu-item-49409" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-49409">
                                           
                                              
                                                 <a href="/signup" >Get Started</a>
                                            </li>
                                            <li id="menu-item-49410" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-49410">
                                            
                                                <NavLink to="/login">Sign In</NavLink>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="col col-6 col-sm-6 col-lg-3">
                                <div class="footer-nav-wrapper py-2 py-md-1 py-lg-0" id="footer_company_navigation">
                                    <div class="nav-header">
                                        Company
                                    </div>
                                    <div class="menu-footer-company-navigation-container">
                                        <ul id="menu-footer-company-navigation" class=" d-flex flex-column" >
                                            <li id="menu-item-47827" class="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-47825 current_page_item menu-item-47827">
                                            
                                            <NavLink to="/services">Services</NavLink>
                                            </li>
                                            
                                            <li id="menu-item-47827" class="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-47825 current_page_item menu-item-47827">
                                            
                                            <NavLink to="/testimonials">Testimonials</NavLink>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                          
                        </div>
                    </div>
                </div>
            </div>
            </div> </div>
    </div>
</div>

   

<div class="row -center hero-row popupde">
    <div class="close2">
        <a class="close-atag" href="javascript:void(0);">
            <img src="assets/images/xlose.png" />
        </a>
    </div>
<img class="feature-callout__img" src="https://luna1.co/005100.png" alt="Image of team" width="112" />
            <div class="signupForm-homepage-center col-xsmall-12 col-small-8 col-small-offset-2 col-medium-10 col-medium-offset-1 col-large-8 col-large-offset-2 col-xlarge-6 col-xlarge-offset-3">
                <h4 class="heading -spacing-2 -h3">Sign up</h4>
                <p class="subheading -spacing-1 hero-legal-copy">
                    By signing up, I agree to the Asana <a href="/terms#privacy-policy" class="css-1kfjk3x-Link">Privacy Policy</a> and <a href="/terms#terms-of-service" class="css-1kfjk3x-Link">Terms of Service</a>.
                </p>
                <form action="get-started/verify" method="get" novalidate="" class="signupForm hidden-logged-in signupForm-homepage-center" __bizdiag="1239199729" __biza="WJ__">
                    <div class="signupForm-container">
                        <div class="signupForm-row">
                        <div class="form-group">
                                
                                <input type="email" name="e" required  class="form-control" placeholder="name@company.com" id="work-email" />
                            </div>

                            <div class="submit-btn">
                                <button id="go-to-nextStep"  onClick={this.toggleBox} class="btn btn-lg btn-flat btn-block btn-primary">Next</button>
                            </div>
                        </div>
                    </div>
                </form>
                
            </div>
        </div>
    </div>
    );
}
}

export default Workpoint;
